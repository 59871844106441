<template>
  <div v-if="metadata != null">
    <cargill-crud-meta-view
      :service="crudService"
      :metadata="metadata"
      translatePrefix="application.pages"
      :validation-schema="createValidationSchema"
    />
  </div>
</template>

<script>
import service from '../api/demandFulfillmentProhibitionService'
import locale from '../locales/en.js'
import {
  CargillCrudMetaView,
  responseToOptionsInContext,
  helpers
} from '@cargill/shared'
import _ from 'lodash'
import { generateFieldsSchema } from '@brain/core'
import * as yup from 'yup'

export default {
  components: { CargillCrudMetaView },
  data() {
    return {
      metadata: null,
      crudService: service,
      createValidationSchema: Function
    }
  },
  created() {
    const getMeta = async () => {
      const meta = await service.getMeta()
      const ctx = await service.getValidationContext()
      responseToOptionsInContext(ctx)

      helpers.updateOptionsRefs(
        ctx.centerOriginOptions,
        ctx.centersOriginByCenterTypeOrigin
      )

      helpers.updateOptionsRefs(ctx.customerOptions, ctx.customersByCustomerVIP)

      const fieldsById = _.keyBy(meta.fields, (f) => f.id)

      // fieldsById.purchaseContractCak.options =
      //   ctx.purchaseContractCakOptions.map((x) => x.label)
      // fieldsById.saleContractCak.options = ctx.saleContractCakOptions.map(
      //   (x) => x.label
      // )

      //fieldsById.purchaseContract.options = ctx.purchaseContractOptions
      //fieldsById.saleContract.options = ctx.saleContractOptions
      fieldsById.centerTypeOrigin.options = ctx.centerTypeOriginOptions
      fieldsById.centerOrigin.options = ctx.centerOriginOptions
      fieldsById.customer.options = ctx.customerOptions
      fieldsById.carrier.options = ctx.carrierOptions
      fieldsById.customerVIP.options = fieldsById.customerVIP.options.filter(
        (option) =>
          option === locale.application.enums.yesOrNo.yes.toLowerCase() ||
          option === locale.application.enums.yesOrNo.no.toLowerCase()
      )

      const getPurchaseContractOptions = (fields) => {
        var result = ctx.purchaseContractOptions.map((x) => x.label)

        if (fields.purchaseContractCompany.value != null) {
          var fieldCompany =
            typeof fields.purchaseContractCompany.value === 'object'
              ? fields.purchaseContractCompany.value.value
              : fields.purchaseContractCompany.value
          result = result.filter((x) => x.company == fieldCompany)
        }
        if (fields.purchaseContractCropYear.value != null) {
          result = result.filter(
            (x) => x.cropYear == fields.purchaseContractCropYear.value
          )
        }
        //console.log(result.length)
        result = result.map((x) => {
          return {
            label: x.contractReference,
            text: x.contractReference,
            value: x.id
          }
        })
        return _.orderBy(result, 'label')
      }

      const getSaleContractOptions = (fields) => {
        var result = ctx.saleContractOptions.map((x) => x.label)

        if (fields.saleContractCompany.value != null) {
          var fieldCompany =
            typeof fields.saleContractCompany.value === 'object'
              ? fields.saleContractCompany.value.value
              : fields.saleContractCompany.value

          result = result.filter((x) => x.company == fieldCompany)
        }
        if (fields.saleContractCropYear.value != null) {
          result = result.filter(
            (x) => x.cropYear == fields.saleContractCropYear.value
          )
        }
        //console.log(result.length)
        result = result.map((x) => {
          return {
            label: x.contractReference,
            text: x.contractReference,
            value: x.id
          }
        })
        return _.orderBy(result, 'label')
      }

      const updatePurchaseContractOptions = (fields) => {
        const purchaseContractOptions = getPurchaseContractOptions(fields)
        const purchaseContractCakOptions = purchaseContractOptions.map((x) =>
          x.label.slice(0, 6)
        )

        helpers.updateOptionsObject(
          this,
          fieldsById.purchaseContract,
          fields.purchaseContract,
          purchaseContractOptions
        )
        helpers.updateOptionsObject(
          this,
          fieldsById.purchaseContractCak,
          fields.purchaseContractCak,
          purchaseContractCakOptions
        )
      }

      const updateSaleContractOptions = (fields) => {
        const saleContractOptions = getSaleContractOptions(fields)
        const saleContractCakOptions = saleContractOptions.map((x) =>
          x.label.slice(0, 6)
        )

        helpers.updateOptionsObject(
          this,
          fieldsById.saleContract,
          fields.saleContract,
          saleContractOptions
        )
        helpers.updateOptionsObject(
          this,
          fieldsById.saleContractCak,
          fields.saleContractCak,
          saleContractCakOptions
        )
      }

      fieldsById.purchaseContractCompany.onValueChange = (
        changedValue,
        fields
      ) => updatePurchaseContractOptions(fields)

      fieldsById.purchaseContractCropYear.onValueChange = (
        changedValue,
        fields
      ) => updatePurchaseContractOptions(fields)

      fieldsById.saleContractCompany.onValueChange = (changedValue, fields) =>
        updateSaleContractOptions(fields)

      fieldsById.saleContractCropYear.onValueChange = (changedValue, fields) =>
        updateSaleContractOptions(fields)

      const getCenterOriginOptions = (value) =>
        value?.value == null
          ? ctx.centerOriginOptions
          : ctx.centersOriginByCenterTypeOrigin[value.value]

      fieldsById.centerTypeOrigin.onValueChange = (changedValue, fields) => {
        helpers.updateOptions(
          this,
          fieldsById.centerOrigin,
          fields.centerOrigin,
          getCenterOriginOptions(changedValue)
        )
      }

      const getCustomerOptions = (value) =>
        value == null ? ctx.customerOptions : ctx.customersByCustomerVIP[value]

      fieldsById.customerVIP.onValueChange = (changedValue, fields) => {
        helpers.updateOptions(
          this,
          fieldsById.customer,
          fields.customer,
          getCustomerOptions(changedValue)
        )
      }

      this.createValidationSchema = (meta, translate) => {
        const validationSchema = generateFieldsSchema(meta, translate)
        const localityFields = helpers.getLocalityFields()
        const localityOriginFields = {}
        localityFields.forEach(
          (field) => (localityOriginFields[field] = field + 'Origin')
        )
        helpers.createBaseLocalityValidations(
          validationSchema,
          translate,
          localityOriginFields
        )
        const localityDestinationFields = {}
        localityFields.forEach(
          (field) => (localityDestinationFields[field] = field + 'Destination')
        )
        helpers.createBaseLocalityValidations(
          validationSchema,
          translate,
          localityDestinationFields
        )

        return yup.object().shape(validationSchema)
      }

      return meta
    }

    getMeta().then((meta) => {
      this.metadata = meta
    })
  }
}
</script>
